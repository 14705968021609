import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-geocercas-list',
  templateUrl: './geocercas-list.page.html',
  styleUrls: ['./geocercas-list.page.scss'],
})
export class GeocercasListPage implements OnInit {

  id:any;
  geocercas:any = [];

  constructor(public navCtrl:NavController, public alertController:AlertController, public route: ActivatedRoute,
              private _api:ApiService, public loadingCtrl: LoadingController) { 

    this.route.params.subscribe((params)=>{
      console.log(params);
      this.id = params.id;
    });

  }

  ngOnInit() {    

  }

  ionViewWillEnter(){
    this.cargandoGeocercas();
  }

  async cargandoGeocercas(){

    const loading = await this.loadingCtrl.create({
      message:"Cargando geocercas",
      backdropDismiss:true
    });

    loading.present();

    let usuario = localStorage.getItem("user");
    this._api.geocercas({ user: usuario }).subscribe((data:any)=>{
      this.geocercas = data.data;
      console.log(this.geocercas);
      loading.dismiss();
    },(err:any)=>{
      loading.dismiss();
    });
  }

  addGeocerca(){
    this.navCtrl.navigateForward(`geocercas/${this.id}`);
  }

  deleteGeocerca( item:any ){
    this.presentAlertConfirmDelete( `Realmente deseas eliminar la geocerca ${item.nombre}`, item );
  }

  async presentAlertConfirmDelete( mensaje:string, item:any ) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Mensaje',
      message: mensaje,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Si',
          id: 'confirm-button',
          handler: () => {            
            this._api.eliminaGeoPDI({ id:item.id, nombre: item.nombre, tipo:1 }).subscribe((data:any)=>{
              this.presentAlert(data.mensaje);   
              this.cargandoGeocercas();           
            });
          }
        }
      ]
    });

    await alert.present();
  }

  async presentAlert( message:any ) {
    const alert = await this.alertController.create({      
      subHeader: 'Mensaje',
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

}
